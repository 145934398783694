import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate,useParams} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import {UserContex} from '../App'
import { Carousel } from 'react-responsive-carousel';

import NavBar from './nav'
import Header from './Header'

import MiniSearch from 'minisearch';
import Footer from './Footer';

const CategoryProduct = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])
  const  [cart, setCart]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

  // const[name,setName]= useState("")
  const[nameEdit,setNameEdit]= useState("")
  const[idEdit,setidEdit]= useState("")
  const[quantity,setQuantity]= useState()
  const[quantityEdit,setQuantityEdit]= useState()
  const[rate,setRate]= useState()
  const[rateEdit,setRateEdit]= useState()

  const {name} =useParams()
  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);

  const [book, setBook] = useState([]);
  const [bookid, setBookId] = useState([]);
  
  console.log("DATA",data)

//   const {
//     transcript,
//     listening,
//     resetTranscript,
//     browserSupportsSpeechRecognition
//   } = useSpeechRecognition();



  useEffect(  ()=>{

    fetch(`/getCategoryProduct/${name}`,{
      headers:{
          // Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{

      setData(result.stock)
      
  })

  },[])


  useEffect(() => {


    const l = JSON.parse(localStorage.getItem('cart'))

    if(l){
      setCart(l)
    }

    // const json = localStorage.getItem("cart");

    // setCart(json)
    // This effect uses the `value` variable,
    // so it "depends on" `value`.
    // setSearch(transcript)
    // fetchPost(transcript)
  }, [])

  useEffect(() => {
    // Create a MiniSearch instance
    const miniSearch = new MiniSearch({
      fields: ['name', 'description','category'], // fields to index for full-text search
      storeFields: ['_id', 'name', 'description','category','pic'], // fields to return with search results
      searchOptions: {
        boost: { realestateType: 3, description: 2, address:2 },
        prefix: true,
        fuzzy: 0.5,
      },
      idField:"_id"
    });

    // Index the products data
    miniSearch.addAll(data);

    // Perform the search
    if (searchQuery) {
      const searchResults = miniSearch.search(searchQuery);
      setResults(searchResults);
    } else {
      setResults([]);
    }
  }, [searchQuery]);

  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/searchall-stock',{

      method:"post",
      headers:{
        // Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.stock)
      })


  }

  const singleStock = (Itemid)=>{

      console.log(Itemid)
    fetch('/getSingleStock',{
      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        id:Itemid
      })
  }).then(res=>res.json())
  .then(result=>{


    // setDataEdit(result.stock)
    console.log(result)
    setidEdit(result.stock._id)
    setNameEdit(result.stock.name)
    setQuantityEdit(result.stock.quantity)
    setRateEdit(result.stock.rate)
            
  })

  }


  const addtoCart = (item)=>{

    setCart(olditem=>[...olditem, item._id])
  
    fetch('/addToCart',{
  
      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
  
        "Content-Type":"application/json"
  
      },
      body:JSON.stringify({
        product:item._id,
        consumer:state._id
        
      })
  
    }).then(res=> res.json())
      .then(results=>{
        
        // setData(results.order)
      })
  
     
  
  const json = localStorage.getItem("cart");
    const previousPosts = json ? JSON.parse(json) : [];
    const updatedPosts = [...previousPosts, item._id];
    // const uniquePosts = Array.from(new Set(updatedPosts))
    const stringifyed = JSON.stringify(updatedPosts);
    localStorage.setItem("cart", stringifyed);
  }
  
  const handleSort = (e) => {
    const value = e.target.value;
    let sortedData = [...data];

    if (value === 'lowToHigh') {
      sortedData = sortedData.sort((a, b) => a.rate - b.rate);
    } else if (value === 'highToLow') {
      sortedData = sortedData.sort((a, b) => b.rate - a.rate);
    }
     else if (value === 'Newest') {
      sortedData = sortedData.reverse();
    }
     else if (value === 'Recommended') {
      sortedData = sortedData.sort(() => Math.random() - 0.5);
    }

    setData(sortedData);
  };
  
  const addtoBook = (item) => {
    setBook(olditem => [...olditem, { product: item }]);
    setBookId(olditem => [...olditem, item._id]);

    const json = localStorage.getItem("book");
    const previousPosts = json ? JSON.parse(json) : [];
    const updatedPosts = [...previousPosts, { product: item }];
    const stringifyed = JSON.stringify(updatedPosts);
    localStorage.setItem("book", stringifyed);

    const jsonid = localStorage.getItem("bookid");
    const previousPostsid = jsonid ? JSON.parse(jsonid) : [];
    const updatedPostsid = [...previousPostsid, item._id];
    const stringifyedid = JSON.stringify(updatedPostsid);
    localStorage.setItem("bookid", stringifyedid);
}



const removeFromBook = (item) => {
    // Remove item from state
    setBook(oldItems => oldItems.filter(bookItem => bookItem.product._id !== item._id));
    setBookId(oldItems => oldItems.filter(bookItemId => bookItemId !== item._id));

    // Remove item from localStorage (book)
    const json = localStorage.getItem("book");
    const previousPosts = json ? JSON.parse(json) : [];
    const updatedPosts = previousPosts.filter(post => post.product._id !== item._id);
    const stringifyed = JSON.stringify(updatedPosts);
    localStorage.setItem("book", stringifyed);

    // Remove item from localStorage (bookid)
    const jsonid = localStorage.getItem("bookid");
    const previousPostsid = jsonid ? JSON.parse(jsonid) : [];
    const updatedPostsid = previousPostsid.filter(id => id !== item._id);
    const stringifyedid = JSON.stringify(updatedPostsid);
    localStorage.setItem("bookid", stringifyedid);
}
return(
<main class="bg-white  h-screen overflow-auto relative">
<div class="flex items-start justify-between">
       
       <div class="flex flex-col w-full ">

           

           <Header></Header>


           
{/* 
           <div>
     <p>Microphone: {listening ? 'on' : 'off'}</p>
     <button onClick={SpeechRecognition.startListening}>Start</button>
     <button onClick={SpeechRecognition.stopListening}>Stop</button>
     <button onClick={resetTranscript}>Reset</button>
     <p>{transcript}</p>
   </div> 
   
   
   */}


<div class=" md:h-80 h-46  w-full   bg-cover bg-top bg-no-repeat" style={{ 
     backgroundImage: `url("/bgimg.png")` 
   }}>

               <div class=" mx-8 sm:mx-20 my-24 sm:my-32 ">

           {/* <div className="w-full bg-white border-none rounded-lg h-16 bg-opacity-80 hover:bg-opacity-100 p-4  focus:outline-none focus:border-white active:outline-none flex focus:outline-none justify-between items-center relative">
<input type="text" name="search" id="search" placeholder={  'Search'} 
       className=" w-full bg-white border-none rounded-lg h-16 bg-opacity-10 hover:bg-opacity-100 p-4  focus:outline-none focus:border-none active:outline-none"
       value={searchQuery}
       onChange={(e) => setSearchQuery(e.target.value)}
       // value= {search} onChange={(e)=>fetchPost(e.target.value)}
       />
<button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
       viewBox="0 0 24 24" className="w-6 h-6 text-lime-600">
    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
  </svg>
</button>


</div> */}

{/* <div class="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow-lg ">
   <ul class="flex flex-col divide-y divide">
   {results.map((result) => {
           return(
         
           <li class="flex flex-row">
                            <a href={`/singleproduct/${result._id}`} class="relative block">
  <div class="flex items-center flex-1 p-4 cursor-pointer select-none">
               <div class="flex flex-col items-center justify-center w-10 h-10 mr-4">
                       <img alt="profil" src={result.pic[0]} class="mx-auto object-cover rounded-sm h-10 w-10 "/>
               </div>
               <div class="flex-1 pl-1 mr-16">
                   <div class="font-medium ">
                    {result.name}
                   </div>
                   <div class="text-sm text-gray-600 ">
                       {result.description}
                   </div>
               </div>
               <div class="text-xs text-gray-600 ">
                   {result.category}
               </div>
           </div>                    </a>

       </li>
           )
       }
       )}
     
     
      
   </ul>
</div> */}

     
           </div>
           </div>





       </div>
    

   </div>
   {/* <div  className=" inset-x-0  top-1/2 transform -translate-y-1/2 flex items-center justify-center -mb-16 md:mt-2 mt-16 "> */}
   <div  className=" inset-x-0  transform  flex items-center justify-center  mt-8 ">

   <div className=" md:w-1/2 w-full ">
     
   {/* <div class="relative w-full overflow-hidden bg-white  shadow-xl rounded-t-lg">
      <div class="z-100 grid md:grid-cols-4 grid-cols-3 gap-2 justify-items-center place-items-center  px-4  py-6 ">
         



          
      </div>
     
</div>

   <div className="w-full bg-white shadow-xl  border-none rounded-b-lg h-16 bg-opacity-100 hover:bg-opacity-100 p-4  focus:outline-none focus:border-white active:outline-none flex focus:outline-none justify-between items-center relative">
<input type="text" name="search" id="search" placeholder={  'Search'} 
   className=" 
   rounded-lg border-lg flex-1 mb-6 appearance-none border border-gray-200 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-transparen
   w-full bg-white border-lg ring-lime-600 rounded-lg h-16 bg-opacity-100 hover:bg-opacity-100 p-4  focus:outline-none focus:border-none active:outline-none"
   value={searchQuery}
   onChange={(e) => setSearchQuery(e.target.value)}
   />
<button type="submit" className="ml-1 mb-6 outline-none border-none focus:border-none  active:outline-none  ">

<svg class="h-8 w-8 text-lime-600  transition-transform duration-300 hover:rotate-12"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="11" cy="11" r="8" />  <line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
</button>


</div>
<div class={`container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow-lg  ${results.length!=0?"h-80":""} overflow-auto `}>
   <ul class="flex flex-col divide-y divide">
   {results.map((result) => {
           return(
         
           <li class="flex flex-row">
                            <a href={`/singleproduct/${result._id}`} class="relative block ">
  <div class="flex items-center flex-1 p-4 cursor-pointer select-none">
               <div class="flex flex-col items-center justify-center w-10 h-10 mr-4">
                       <img alt="profil" src={result.pic[0]} class="mx-auto object-cover rounded-sm h-10 w-10 "/>
               </div>
               <div class="flex-1 pl-1 mr-16">
                   <div class="font-medium ">
                    {result.name}
                   </div>
                   <div class="text-sm text-gray-600 ">
                       {result.description}
                   </div>
               </div>
               <div class="text-xs text-gray-600 ">
                   {result.category}
               </div>
           </div>                    </a>

       </li>
           )
       }
       )}
     
     
      
   </ul>
</div> */}

   </div>
 </div>
       
   <div class="flex flex-col bg-white  ">

<div
 class="flex overflow-x-scroll   hide-scroll-bar"
>
 <div
   class="flex flex-nowrap md:h-20 h-12    ml-4 "
 >
    <div class="px-1 md:py-6 py-2">
               
   
    <select 
        className="x-4 py-2 flex items-center text-base font-bold text-xl underline" 
        onChange={handleSort}
      >
        <option value="">Sort By</option>
        <option value="Recommended">Recommended</option>  
              <option value="Newest">Newest</option>

        <option value="lowToHigh">Price Low to High</option>
        <option value="highToLow">Price High to Low</option>
      </select>
                   </div>
                  
 </div>
</div>
</div> 




<div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 m-4">
          {data.map((product) => (
           <div key={product._id} className="w-80 h-full p-2 ">

           <div className=" w-full overflow-hidden rounded-md bg-gray-200 relative">

             {bookid.includes(product._id)?  <svg
       className="h-5 w-5 text-red-500 absolute top-2 right-2"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       strokeWidth="2"
       stroke="currentColor"
       fill="none"
       strokeLinecap="round"
       strokeLinejoin="round"

       onClick={()=>{removeFromBook(product)}}
     >
       <path stroke="none" d="M0 0h24v24H0z" />
       <path fill={`${"currentColor"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
     </svg>:

             <svg
       className="h-5 w-5 text-red-500 absolute top-2 right-2"
       width="24"
       height="24"
       viewBox="0 0 24 24"
       strokeWidth="2"
       stroke="currentColor"
       fill="none"
       strokeLinecap="round"
       strokeLinejoin="round"

       onClick={()=>{addtoBook(product)}}
     >
       <path stroke="none" d="M0 0h24v24H0z" />
       <path fill={`${"none"}`} d="M12 20l-7 -7a4 4 0 0 1 6.5 -6a.9 .9 0 0 0 1 0a4 4 0 0 1 6.5 6l-7 7" />
     </svg>
         }
         


     
     <Link to={`/singleproduct/${product._id}`}>

             <img
               alt={product.imageAlt}
               src={product.pic[0]}
               className="h-80 w-80 object-cover object-center "
             />
                           </Link>

           </div>
           <div className="mt-4 flex justify-between">
             <div>
               <h3 className="text-sm text-gray-700">
                   <span aria-hidden="true" className=" inset-0" />
                   {product.name}
               </h3>
               {/* <p className="mt-1 text-sm text-gray-500">{product.color}</p> */}
             </div>
             {/* <p className="text-sm font-medium text-gray-900">{selectedCurrency.value} {product.rate * selectedCurrency.conv}</p> */}
           </div>
         </div>
          ))}
        </div>





    









<Footer></Footer>

</main>
);


  
}


export default CategoryProduct