
import React,{useContext, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import Header from './Header';

import {UserContex} from '../App'
import Footer from './Footer';


const Stories = ()=>{
  const {state, dispatch }= useContext(UserContex)
  const history =useNavigate();
  const [addStock,setAddStock]= useState(false)
  const [editEmoloyee,setEdit]= useState(false)
  const[id,setId] = useState("")  
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[cid,setCID]= useState("")
  const[skills,setSkills]=useState([])
  const[docs,setDocs]=useState([])
  const[salary,setSalary]= useState("")
  const [dob,setDob]= useState(new Date())
  const [url,setUrl] = useState("")  

  const PostData =()=>{

   
//     fetch("/signinemployee",{
//       method:"post",
//       headers:{
//           "Content-Type":"application/json"
//       },
//       body: JSON.stringify({
        
//           password,
//           email:email
//       })
      
//   }).then(res=>res.json())
//   .then(data=> {
     
//       if(data.error){
//         toast.error(data.error)

//       }
//       else{
//         console.log("Login Successful")
//           localStorage.setItem("jwt", data.token)
//           localStorage.setItem("user", JSON.stringify( data.user))
//           dispatch({type:"USER", payload:data.user})
//           history.push('/dashboard')
//       }
//   })
//   .catch(err=>{
//       console.log(err)
//   })

       }

    return(


<>
<Header></Header>



<div>
<h1 class=" text-4xl text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  mt-20">
    Our Stories
</h1>
    <div class="relative z-20 flex items-center overflow-hidden bg-white ">
  

  



    
</div>

<div className='grid md:grid-cols-2 grid-cols-1 h-screen p-2 gap-2 mt-6'>
      {/* First Div */}
      <div id="wrapper-women" className="relative md:h-screen md:w-full h-full">
        {/* Background image */}
        <div
          className="absolute inset-0  bg-contain bg-top bg-no-repeat"
          style={{ backgroundImage: `url(/barb.png)` }}
        >
          {/* Overlay */}
          <div className="absolute inset-0 bg-white opacity-10"></div>
        </div>
        {/* Content */}
    

      </div>

      {/* Second Div */}
      <div id="wrapper-men" className="relative md:h-screen md:w-full  h-full">
        {/* Background image */}
        <div
          className="absolute inset-0 bg-contain bg-top bg-no-repeat"
          style={{ backgroundImage: `url(/uni.jpeg)` }}
        >
          {/* Overlay */}
          <div className="absolute inset-0 bg-white opacity-10"></div>
        </div>
      
     
      </div>
    </div>


<div class="p-8 bg-white rounded-lg shadow ">
    <p class="text-3xl font-bold text-center text-gray-800 ">
    Testimonials
    </p>
    <p class="mb-12 text-xl font-normal text-center text-gray-500 ">
              Listen what our customers has to say
   </p>
    
<div class="flex flex-col items-center w-full gap-4 p-8 mb-8 md:flex-row md:mb-0 flex-between">
    <div class="bg-white  w-72 shadow-lg mx-auto rounded-xl p-4">
        <p class="text-gray-600 ">
            <span class="text-lg font-bold text-indigo-500">
                “
            </span>
            Finally, I own a good design Nettle plant product which I wanted for a long time!
            <span class="text-lg font-bold text-indigo-500">
                ”
            </span>
        </p>
        <div class="flex items-center mt-4">
            <a href="#" class="relative block">
                <img alt="profil" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToK4qEfbnd-RN82wdL2awn_PMviy_pelocqQ&s" class="mx-auto object-cover rounded-full h-10 w-10 "/>
            </a>
            <div class="flex flex-col justify-between ml-2">
                <span class="text-sm font-semibold text-indigo-500">
                Chartlada Sangakji
                </span>
                <span class="flex items-center text-xs dark:text-gray-400">
                Thailand
                </span>
            </div>
        </div>
    </div>
    <div class="bg-white  w-72 shadow-lg mx-auto rounded-xl p-4">
        <p class="text-gray-600 ">
            <span class="text-lg font-bold text-indigo-500">
                “
            </span>
            The nicest designs I have seen integrating traditional fabric, designs and skill into modern clothes and more
            <span class="text-lg font-bold text-indigo-500">
                ”
            </span>
        </p>
        <div class="flex items-center mt-4">
            <a href="#" class="relative block">
                <img alt="profil" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToK4qEfbnd-RN82wdL2awn_PMviy_pelocqQ&s" class="mx-auto object-cover rounded-full h-10 w-10 "/>
            </a>
            <div class="flex flex-col justify-between ml-2">
                <span class="text-sm font-semibold text-indigo-500">
                Neil Buhne
                </span>
                <span class="flex items-center text-xs dark:text-gray-400">
                Switzerland
                </span>
            </div>
        </div>
    </div>
    <div class="bg-white  w-72 shadow-lg mx-auto rounded-xl p-4">
        <p class="text-gray-600 ">
            <span class="text-lg font-bold text-indigo-500">
                “
            </span>
            The Mountain Crafts jackets are a powerhouse of warmth and style! The craftsmanship is top-notch, and it kept me warm and comfortable during my recent trip to Europe. The unique design turns heads everywhere I go. If you're looking for style and functionality, look no further!                        <span class="text-lg font-bold text-indigo-500">
                ”
            </span>
        </p>
        <div class="flex items-center mt-4">
            <a href="#" class="relative block">
                <img alt="profil" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToK4qEfbnd-RN82wdL2awn_PMviy_pelocqQ&s" class="mx-auto object-cover rounded-full h-10 w-10 "/>
            </a>
            <div class="flex flex-col justify-between ml-2">
                <span class="text-sm font-semibold text-indigo-500">
                Tandin Bidha, Bhutanese actor, storyteller and a filmmaker            </span>
                
                <span class="flex items-center text-xs dark:text-gray-400">
                Bhutan
                </span>
            </div>
        </div>
    </div>
</div>

</div>

  
{/* <h1 class=" text-2xl mt-10 text-center font-black leading-none text-gray-800 uppercase font-bebas-neue  ">
    Partners of Mountain Crafts    </h1>

    <ul class="flex flex-wrap items-center gap-8">
            <li class="w-full">
               
                <p class="mt-2">
                    <p class="text-base pl-24 pr-24 leading-6 text-gray-500 text-justify mb-2">
                    SABAH Bhutan, established in 2010, is an NGO dedicated to uplifting home-based weavers in Bhutan. Their mission focuses on enhancing and marketing traditional skills, effectively reviving ancient art forms and designs. By providing a platform for these artisans, SABAH Bhutan not only preserves Bhutanese cultural heritage but also empowers local communities economically. Their partnership with Mountain Crafts Bhutan amplifies the reach and impact of both organizations in promoting sustainable, artisanal craftsmanship.
           </p>
                  
              
                </p>
                <p class="mt-2">
                    <p class="text-base pl-24 pr-24 leading-6 text-gray-500 text-justify mb-2">
                    Tribal Trust Foundation (TTF), founded in 1996, is committed to supporting and preserving indigenous cultures worldwide. Their approach involves identifying and nurturing sustainable grassroots cultural preservation projects. TTF's strength lies in its strategic partnerships with local organizations that have deep-rooted connections with specific tribes. This collaborative model ensures long-term sustainability of initiatives aimed at preserving living arts and traditional wisdom. Through their partnership with Mountain Crafts Bhutan, TTF extends its mission to the unique cultural landscape of Bhutan.
                    </p>
                  
              
                </p>
            </li>
          
       
        </ul> */}

</div>

<Footer></Footer>


</>
  


    );
}

export default Stories;