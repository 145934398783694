
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Footer(props) {
  const [isOpen, setIsOpen] = useState(false);
  const cart = JSON.parse(localStorage.getItem("cart"));
  const navigate = useNavigate()
  const[name,setName]= useState("")
  const[phone,setPhone]= useState("")
  const[address,setAddress]= useState("")
  const[email,setEmail]= useState("")
  const[password,setPassword]= useState("")
  const[confirmpassword,setConfirmPassword]= useState("")
  const [saw, setSaw] = useState(false)
  const history = useNavigate()


  const PostData =()=>{
    const password = Array.from({ length: 12 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?'[Math.floor(Math.random() * 86)]).join('');
    const coupon = Array.from({ length: 5 }, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'[Math.floor(Math.random() * 86)]).join('');
    
    fetch("/signupcouponConsumer",{
      method:"post",
      headers:{
          "Content-Type":"application/json"
      },
      body: JSON.stringify({
        
          password:password,
          email:email,
          coupon:coupon
      })
      
  }).then(res=>res.json())
  .then(data=> {
  
    
     
      if(data.error){
        toast.error(data.error)
        localStorage.setItem("seen", true)
        setSaw(false)
  
      }
      else{
        toast.success("Signup Sucessful, Cheack your email")
  
        localStorage.setItem("seen", true)
        setSaw(false)      
          history('/consumerlogin')
      }
  })
  .catch(err=>{
      console.log(err)
  })
  
       }
  
  return (
  

    <>
    <ToastContainer></ToastContainer>
    
{saw?

<div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
<div className='p-24 mt-12'>


<div class="flex flex-wrap w-full   shadow-2xl ">
<div class="w-1/2">
                <img class="hidden object-cover w-full h-screen md:block" src="/sign.jpeg"/>
            </div>
    <div class="flex flex-col w-96 md:w-1/2 relative bg-white">
    <svg class="h-8 w-8 text-black absolute top-0 right-0 "  fill="none" viewBox="0 0 24 24" stroke="currentColor"
    
    onClick={()=>{

      localStorage.setItem("seen", true)
      setSaw(false)
    }}
    >
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
</svg>

        <div class="flex flex-col justify-center px-8 pt-8 my-auto md:justify-start md:pt-0 md:px-24 lg:px-32">
            <p class="text-5xl font-extrabold text-center mb-8">
                Mountain Crafts
            </p>
            <p class="text-3xl text-center mb-2">
                Enjoy 15% off.
            </p>
            <p class="text-sm  text-center">
            Sign up now to receive exclusive updates and offers from Mountain Crafts. Enter your email below and get a 15% off coupon sent directly to your inbox!

By submitting this form, you agree to receive email marketing messages from Mountain Crafts at the provided email address. You can withdraw your consent at any time by following the unsubscribe instructions in any email we send. For inquiries, contact us at mountaincrafts@google.com.            </p>
            <div class="flex flex-col pt-3 md:pt-8">
                <div class="flex flex-col pt-4 mb-12">
                    <div class="flex relative ">
                        <span class=" inline-flex  items-center px-3 border-t bg-white border-l border-b  border-gray-300 text-gray-500 shadow-sm text-sm">
                            <svg width="15" height="15" fill="currentColor" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z">
                                </path>
                            </svg>
                        </span>
                        <input type="text" id="design-login-email" class=" flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent" placeholder="Email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        />
                        </div>
                    </div>
                   
                        <button  class="w-full px-4 py-2 mb-4 text-base font-semibold text-center text-white transition duration-200 ease-in bg-black shadow-md hover:text-black hover:bg-white focus:outline-none focus:ring-2"
                        onClick={()=>{PostData()}}
                        >
                            <span class="w-full">
                                Sign Up
                            </span>
                        </button>
                    </div>
                    {/* <div class="pt-12 pb-12 text-center">
                        <p>
                            Don&#x27;t have an account?
                            <a href="#" class="font-semibold underline">
                                Register here.
                            </a>
                        </p>
                    </div> */}
                </div>
            </div>
           
        </div>
        </div>

</div>

:<></>}
    
    {/* <footer class="bg-gray-100 w-full py-8 lg:visible invisible">
    <div class="max-w-screen-xl px-4 mx-auto">
        <ul class="flex flex-wrap justify-between max-w-screen-md mx-auto text-lg font-light">
            <li class="my-2">
                <a class="text-gray-400 hover:text-gray-800  transition-colors duration-200" href="/about">
                    About Us
                </a>
            </li>
            <li class="my-2">
                <a class="text-gray-400 hover:text-gray-800  transition-colors duration-200" href="/contact">
                    Contact Us
                </a>
            </li>
         
            <li class="my-2">
                <a class="text-gray-400 hover:text-gray-800  transition-colors duration-200" href="/stories" >
                    Impact
                </a>
            </li>
        </ul>
        <div class="pt-8 flex max-w-xs mx-auto items-center justify-center">
            <a href="https://www.facebook.com/mountaincraftsbtn" target="_blank" class="text-gray-400 transition-colors duration-200 hover:text-gray-800 mr-2 ">
                <svg width="20" height="20" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                    </path>
                </svg>
            </a>
            <a href="https://www.instagram.com/mountaincrafts_bhutan/"  target="_blank" class="text-gray-400 transition-colors duration-200 hover:text-gray-800 ">
            <svg class="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="4" width="16" height="16" rx="4" />  <circle cx="12" cy="12" r="3" />  <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" /></svg>
            </a>
           
         
        </div>
    </div>
</footer> */}
    
{/* <footer class="bg-white  pt-4 pb-8 xl:pt-8">
    <div class=" px-4  text-gray-400  sm:px-6 md:px-8 dark:text-gray-300">
        <ul class="flex  justify-center pb-8 text-lg font-light">
            <li class="w-1/2 md:w-1/3 lg:w-1/3">
                <div class="text-center">
                    <h2 class="text-gray-500 dark:text-gray-200 text-md uppercase mb-4">
                        About
                    </h2>
                    <ul>
                        <li class="mb-4 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">
                            <a href="/about">
                                Our Mission
                            </a>
                        </li>
                        <li class="mb-4 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">
                            <a href="/materials">
                                Our Materials
                            </a>
                        </li>
                        <li class="mb-4 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">
                            <a href="/about/#team">
                                Our Team
                            </a>
                        </li>
                        
                    </ul>
                </div>
            </li>
            <li class="w-1/2 md:w-1/3 lg:w-1/3">
            <a href="/contact">
                <div class="text-center">
                    <h2 class="text-gray-500 dark:text-gray-200 text-md uppercase mb-4">
                        Contacts
                    </h2>
                   
                </div> 
            </a>
            <div class="pt-8 flex max-w-xs mx-auto items-center justify-center">
            <a href="https://www.facebook.com/mountaincraftsbtn" target="_blank" class="text-gray-400 transition-colors duration-200 hover:text-gray-800 mr-2 ">
                <svg width="20" height="20" fill="currentColor" class="text-xl transition-colors duration-200 hover:text-gray-800 " viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759h-306v-759h-255v-296h255v-218q0-186 104-288.5t277-102.5q147 0 228 12z">
                    </path>
                </svg>
            </a>
            <a href="https://www.instagram.com/mountaincrafts_bhutan/"  target="_blank" class="text-gray-400 transition-colors duration-200 hover:text-gray-800 ">
            <svg class="h-6 w-6 text-gray-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <rect x="4" y="4" width="16" height="16" rx="4" />  <circle cx="12" cy="12" r="3" />  <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" /></svg>
            </a>
           
         
        </div>
            </li>
            <li class="w-1/2 md:w-1/3 lg:w-1/3">
                <div class="text-center">
                    <h2 class="text-gray-500 dark:text-gray-200 text-md uppercase mb-4">
                        Impacts
                    </h2>
                    <ul>
                        <li class="mb-4 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">
                            <a href="/stories">
                                Stories
                            </a>
                        </li>
                        <li class="mb-4 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">
                            <a href="/philanthropicgiving">
                         Philanthropic Giving
                            </a>
                        </li>
                        <li class="mb-4 transition-colors duration-200 hover:text-gray-800 dark:hover:text-white">
                            <a href="/partner-with-us">
                                Partner With Us
                            </a>
                        </li>
                       
                    </ul>
                </div>
            </li>
        </ul>
   
    </div>
</footer> */}

<footer className="bg-gray-900 text-gray-200 py-12">
      {/* Top Section */}
      <div className="container mx-auto px-4 flex flex-col items-center lg:items-start lg:flex-row lg:justify-between space-y-8 lg:space-y-0">
        <div className="text-center lg:text-left">
          <h2 className="text-lg font-medium mb-4">

          Email Sign-Up

          </h2>
          <h3 className="">

          <a onClick={()=>setSaw(true)} className="underline mr-1" 
          
          > Sign up </a> 
          
           for Louis Vuitton emails and receive the latest news from the Maison, 

          </h3>
          <h3 className="">

          
          including exclusive online pre-launches and new collections.
          </h3>
          {/* <div className="flex justify-center lg:justify-start">
            <input
              type="email"
              placeholder="EMAIL ADDRESS"
              className="bg-gray-800 text-gray-300 px-4 py-2 rounded-l-md outline-none focus:ring-2 focus:ring-gray-600"
            />
            <button className="bg-gray-800 text-gray-300 px-4 py-2 rounded-r-md border-l border-gray-700 hover:bg-gray-700">
              →
            </button>
          </div> */}
        </div>

        {/* Footer Links */}
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-8 text-sm lg:gap-16">
          {/* Products Section */}
          <div>
            <h3 className="text-gray-400 uppercase font-semibold mb-4">Products</h3>
            <ul className="space-y-2">
              <li>
                <a href="/productcategory/men" className="text-gray-300 hover:underline">
                  Men
                </a>
              </li>
              <li>
                <a href="/productcategory/women" className="text-gray-300 hover:underline">
                  Women
                </a>
              </li>
              <li>
                <a href="/productcategory/decore" className="text-gray-300 hover:underline">
                  Home
                </a>
              </li>
              <li>
                <a href="/productcategory/bag" className="text-gray-300 hover:underline">
                  Bags
                </a>
              </li>
             
             
            </ul>
          </div>

          {/* Quince Section */}
          <div>
            <h3 className="text-gray-400 uppercase font-semibold mb-4">Mountain Crafts</h3>
            <ul className="space-y-2">
              <li>
                <a href="/about" className="text-gray-300 hover:underline">
                  About Us
                </a>
              </li>
            
              <li>
                <a href="/mission" className="text-gray-300 hover:underline">
                  Our Mission
                </a>
              </li>
             
              <li>
                <a href="/team" className="text-gray-300 hover:underline">
                  Our Team
                </a>
              </li>
             
              <li>
                <a href="/stories" className="text-gray-300 hover:underline">
                  Our impact
                </a>
              </li>
              <li>
                <a href="/sustainable" className="text-gray-300 hover:underline">
                Sustainability                </a>
              </li>
           

              <li>
                <a href="/philanthropicgiving" className="text-gray-300 hover:underline">
                Philanthropic Giving
                </a>
              </li>
              <li>
                <a href="/partner-with-us" className="text-gray-300 hover:underline">
                  Refer & Earn
                </a>
              </li>
             
              <li>
                <a href="/materials" className="text-gray-300 hover:underline">
                Our Materials
                </a>
              </li>
              <li>
                <a href="/nettle" className="text-gray-300 hover:underline">
                  Himalayan nettle
                </a>
              </li>
              <li>
                <a href="/cotton" className="text-gray-300 hover:underline">
                  Organic cotton
                </a>
              </li>
              <li>
                <a href="/kishuthara" className="text-gray-300 hover:underline">
                Kishuthara Silk
                </a>
              </li>
              <li>
                <a href="/silk-on-cotton" className="text-gray-300 hover:underline">
                  Silk on Cotton
                </a>
              </li>
              <li>
                <a href="/yathra" className="text-gray-300 hover:underline">
                Yathra
                </a>
              </li>
              <li>
                <a href="/natural-dyes" className="text-gray-300 hover:underline">
                Natural dyes
                </a>
              </li>
            
              
            
            </ul>
          </div>

          {/* Customer Service Section */}
          <div>
            <h3 className="text-gray-400 uppercase font-semibold mb-4">Customer Service</h3>
            <ul className="space-y-2">
              <li>
                <a href="/profile" className="text-gray-300 hover:underline">
                  My Account
                </a>
              </li>
              <li>
                <a href="/faq" className="text-gray-300 hover:underline">
                  FAQ
                </a>
              </li>
              <li>
                <a href="/returnpolicy" className="text-gray-300 hover:underline">
                Return Policy                </a>
              </li>
              {/* <li>
                <a href="#" className="text-gray-300 hover:underline">
                  My Orders
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-300 hover:underline">
                  Start a Return
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-300 hover:underline">
                  Shipping & Returns
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-300 hover:underline">
                  Warranty Policy
                </a>
              </li>
             */}
              <li>
                <a href="/contact" className="text-gray-300 hover:underline">
                  Contact Us
                </a>
              </li>
            </ul>
            <h3 className="text-gray-400 uppercase font-semibold mt-8 mb-4">Wholesale & Gifting</h3>
            <ul className="space-y-2">
              <li>
                <a href="#" className="text-gray-300 hover:underline">
                  Corporate Gifting
                </a>
              </li>
              <li>
                <a href="#" className="text-gray-300 hover:underline">
                  Interior Design
                </a>
              </li>
            </ul>
            <h3 className="text-gray-400 uppercase font-semibold mt-8 mb-4">Follow Us</h3>
            <ul className="space-y-2">
              <li>
                <a href="https://www.facebook.com/mountaincraftsbtn" className="text-gray-300 hover:underline">
                Facebook
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/mountaincrafts_bhutan/" className="text-gray-300 hover:underline">
                Instagram
                </a>
              </li>
              {/* <li>
                <a href="#" className="text-gray-300 hover:underline">
                TikTok
                </a>
              </li> */}
            </ul>
          </div>
        </div>

      
      </div>
  {/* Social Media */}
  {/* <div className="flex items-center justify-center">
    <div className="text-center lg:text-left">
          <h3 className="text-gray-400 uppercase font-semibold mb-4 text-center">Follow Us</h3>
          <div className="flex space-x-4 justify-center lg:justify-start">
            <a href="#" className="text-gray-300 hover:text-white">Facebook</a>
            <a href="#" className="text-gray-300 hover:text-white">Instagram</a>
            <a href="#" className="text-gray-300 hover:text-white">TikTok</a>
          </div>
        </div>
  </div> */}
  
      {/* Bottom Section */}
      <div className="border-t border-gray-800 mt-12 pt-8 text-sm text-gray-400 text-center lg:text-left">
        <div className="container mx-auto px-4">
          <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start space-y-4 lg:space-y-0">
            <p>
              Let's talk, <a href="#" className="text-gray-300 underline">email us</a> and we’ll get back to you within 24 hours.
            </p>
            <p>© 2024 Mountain Crafts. All Rights Reserved.</p>
            <div className="flex space-x-6">
              <a href="#" className="hover:underline">Terms of Service</a>
              <a href="#" className="hover:underline">Privacy Policy</a>
              <a href="#" className="hover:underline">Accessibility</a>
              <a href="#" className="hover:underline">Referral Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
<div class=" lg:invisible visible fixed bottom-0  w-full ">



<div class="relative w-full p-2  bg-white shadow-lg rounded-t-xl  ">
   
    <div class="grid grid-cols-4 gap-4">
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/")}}

        >
            <div class="relative">
                <a href="#" class="relative block">
                <svg class={`h-6 w-6 ${props.page === "home" ? "text-lime-600 " : "text-gray-500 "}`} viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
    <polyline points="9 22 9 12 15 12 15 22" />
</svg>                
                
                
                                      </a>
               
            </div>
            <span class=" text-xs text-gray-600 ">
                Home
            </span>
        </div>
        
      
     
        <div class="flex flex-col items-center"
        
        onClick={()=>{navigate("/shop")}}
        >
            <div class="relative">
            <svg class={`h-6 w-6 ${props.page === "shop" ? "text-lime-600 " : "text-gray-500 "} `} 
viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="11" cy="11" r="8" />  <line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
            </div>
            <span class=" text-xs text-gray-600 ">
                Shop
            </span>
        </div>
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/cart")}}

        >
            <div class="relative">
                {/* <a  class="relative block">
                <svg class={`h-6 w-6 ${props.page === "cart" ? "text-purple-500 " : "text-gray-500"}`}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />  <circle cx="12" cy="7" r="4" /></svg>                </a>
                
                 */}
                <svg class={`h-6 w-6 ${props.page === "cart" ? "text-lime-500 " : "text-gray-500"}`} viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="9" cy="21" r="1" />  <circle cx="20" cy="21" r="1" />  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>
<button type="button" class="absolute  right-0 w-4 h-4 -mt-6   text-white bg-red-600 rounded-full fill-current">
<span class="text-xs">
{cart?cart.length:"0"}
</span>
</button>
            </div>
            {/* <a class="block mt-4 text-blue-900 lg:inline-block hover:text-indigo-600" href="/cart">

<svg class="h-10 w-8 text-slate-500 top-0 right-0 absolute  "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="9" cy="21" r="1" />  <circle cx="20" cy="21" r="1" />  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>
<button type="button" class="absolute  right-0 w-5 h-5 -mt-3   text-white bg-red-600 rounded-full fill-current">
<span class="text-xs">
{cart?cart.length:"0"}
</span>
</button>
</a> */}
            <span class=" text-xs text-gray-600 ">
                Cart
            </span>
        </div>
        <div class="flex flex-col items-center"
                onClick={()=>{navigate("/profile")}}

        >
            <div class="relative">
                <a href="#" class="relative block">
                <svg class={`h-6 w-6 ${props.page === "profile" ? "text-lime-500 " : "text-gray-500"}`}  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />  <circle cx="12" cy="7" r="4" /></svg>                </a>
              
            </div>
            <span class=" text-xs text-gray-600 ">
                Profile
            </span>
        </div>
    </div>
</div>

</div>
    </>



  );
}

export default Footer;

