import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import Select from "react-select";

import MiniSearch from 'minisearch';
function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const cart = JSON.parse(localStorage.getItem("cart"));
  const book = JSON.parse(localStorage.getItem("book"));
  const [isShopOpen, setIsShopOpen] = useState(false); // Dropdown state for "Shop"
  const [isGiftShopOpen, setIsGiftShopOpen] = useState(false); // Dropdown state for "Shop"
  const [isShopOpenstory, setIsShopOpenstory] = useState(false); // Dropdown state for "Shop"
  const [isAboutOpen, setIsAboutOpen] = useState(false); // Dropdown state for "Shop"
  const  [data, setData]= useState([])

  const [searchQuery, setSearchQuery] = useState('');
  const [results, setResults] = useState([]);

  const conversionRates = {
    USD: 1,    // Base rate
    EUR: 0.85, // Example rates
    INR: 75,
    BTN: 82,
    CAD: 1.25, // Canadian Dollar
  };
  const currencyOptions = [
    { value: 'USD', label: 'USD - US Dollar', conv: 1 },
    { value: 'EUR', label: 'EUR - Euro', conv: 0.85 },
    { value: 'INR', label: 'INR - Indian Rupee', conv: 84.17 },
    // { value: 'NU', label: 'BTN - Bhutanese Ngultrum', conv: 82 },
    { value: 'CAD', label: 'CAD - Canadian Dollar', conv: 1.25 },
  ];



  // const currencyOptions = [
  //   { value: 'USD', label: 'USD - US Dollar', conv:1 },
  //   { value: 'EUR', label: 'EUR - Euro', conv:0.85 },
  //   { value: 'INR', label: 'INR - Indian Rupee', conv:75 },
  //   { value: 'BTN', label: 'BTN - Bhutanese Ngultrum', conv:82 },
  //   { value: 'CAD', label: 'CAD - Canadian Dollar', conv:1.25 },
  // ];


  // const [selectedCurrency, setSelectedCurrency] = useState(() => {
  //   const savedCurrency = localStorage.getItem('currency');
  //   return savedCurrency ? JSON.parse(savedCurrency) : { value: 'USD', label: 'USD - US Dollar', conv:1 };
  // });


  // useEffect(() => {
  //   localStorage.setItem('currency', JSON.stringify(selectedCurrency));
  // }, [selectedCurrency]);

  const [selectedCurrency, setSelectedCurrency] = useState(() => {
    const savedCurrency = localStorage.getItem('currency');
    // window.location.reload(false)

    return savedCurrency ? JSON.parse(savedCurrency) : currencyOptions[0]; // Default to USD
  });

  // Save selected currency in localStorage
  // useEffect(() => {
  //   localStorage.setItem('currency', JSON.stringify(selectedCurrency));
  // }, [selectedCurrency]);
  const handleCurrencyChange = (option) => {
    setSelectedCurrency(option);
    localStorage.setItem('currency', JSON.stringify(option));
    window.location.reload();  // Refresh the page after selecting the currency
  };




  useEffect(  ()=>{

    fetch('/getallStock',{
      headers:{
          // Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.stocks)
      
  })

  },[])


  useEffect(() => {
    // Create a MiniSearch instance
    const miniSearch = new MiniSearch({
      fields: ['name', 'description','category'], // fields to index for full-text search
      storeFields: ['_id', 'name', 'description','category','pic'], // fields to return with search results
      searchOptions: {
        boost: { realestateType: 3, description: 2, address:2 },
        prefix: true,
        fuzzy: 0.5,
      },
      idField:"_id"
    });

    // Index the products data
    miniSearch.addAll(data);

    // Perform the search
    if (searchQuery) {
      const searchResults = miniSearch.search(searchQuery);
      setResults(searchResults);
    } else {
      setResults([]);
    }
  }, [searchQuery]);
  

  return (
    <nav className="fixed top-0 left-0 w-full z-50 bg-white shadow-md transition-transform duration-300 ease-in-out">
      <div className="flex flex-wrap items-center justify-between p-4 bg-white">
      <div className="w-auto lg:order-2 lg:w-1/5 lg:text-center">
          <a className="text-xl font-semibold text-gray-800 font-heading flex items-center" href="/">
            <img src="/logo.png" className="h-8 w-12 mr-2" alt="Logo" />
            Mountain Crafts
          </a>
        </div>
        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 text-indigo-500 border border-indigo-500 rounded"
          >
            {/* <svg className="w-6 h-6" viewBox="0 0 20 20" fill="currentColor">
              <path
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"}
              />
            </svg> */}
              {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
          </button>
        </div>
        <div className="hidden w-full lg:flex lg:order-1 lg:w-2/5">
          <Link to="/" className="block mt-4 mr-10 text-lg text-black lg:mt-0 hover:text-indigo-600">
            Home
          </Link>
          <div
            className="relative inline-block"
            onMouseEnter={() => setIsShopOpenstory(true)}
            onMouseLeave={() => setIsShopOpenstory(false)}
          >
            <Link
              className="block mt-4 mr-10 text-black text-lg lg:inline-block lg:mt-0 hover:text-indigo-600"
              to="/stories"
            >
              Impact
            </Link>

            {/* Dropdown menu */}
            {isShopOpenstory && (
              <div className="absolute left-0 top-full  w-48 bg-white shadow-lg rounded-md">
                <ul className="py-2">
                  <li>
                    <Link
                      to="/stories"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Our Stories
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/philanthropicgiving"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Philanthropic Giving
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/partner-with-us"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Partner with us
                    </Link>
                  </li>
                  
                </ul>
              </div>
            )}
          </div>
          <div
            className="relative inline-block"
            onMouseEnter={() => setIsAboutOpen(true)}
            onMouseLeave={() => setIsAboutOpen(false)}
          >
            <Link
              className="block mt-4 mr-10 text-black  text-lg lg:inline-block lg:mt-0 hover:text-indigo-600"
              to="/about"
            >
              About
            </Link>

            {/* Dropdown menu */}
            {isAboutOpen && (
              <div className="absolute left-0 top-full text-lg  w-48 bg-white shadow-lg rounded-md">
                <ul className="py-2">
                  <li>
                    <Link
                      to="/mission"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Our Mission
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/materials"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Our Materials & Care
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/team"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Our Team
                    </Link>
                  </li>
                  
                  <li>
                    <Link
                      to="/sustainable"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                    Sustainability
                    </Link>
                  </li>
                  
                  <li>
                    <Link
                      to="/faq"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                    FAQ
                    </Link>
                  </li>
                  
                  <li>
                    <Link
                      to="/returnpolicy"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                    Return Policy
                    </Link>
                  </li>
                  
                </ul>
              </div>
            )}
          </div>
          <div
            className="relative inline-block"
            onMouseEnter={() => setIsShopOpen(true)}
            onMouseLeave={() => setIsShopOpen(false)}
          >
            <Link
              className="block mt-4 mr-10 text-black text-lg lg:inline-block lg:mt-0 hover:text-indigo-600"
              to="/shop"
            >
              Shop
            </Link>

            {/* Dropdown menu */}
            {isShopOpen && (
              <div className="absolute right-0 top-full text-center w-48 bg-white shadow-lg rounded-md">
                <ul className="py-2">
                  <li>
                    <Link
                      to="/productcategory/women"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Women
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/productcategory/men"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Men
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/productcategory/bags"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Bags
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/productcategory/decor"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Home Decor
                    </Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div
            className="relative inline-block"
            onMouseEnter={() => setIsGiftShopOpen(true)}
            onMouseLeave={() => setIsGiftShopOpen(false)}
          >
            <Link
              className="block mt-4 mr-10 text-black text-lg lg:inline-block lg:mt-0 hover:text-indigo-600"
              to="/giftguide"
            >
              Gift Guide
            </Link>

            {/* Dropdown menu */}
            {/* {isGiftShopOpen && (
              <div className="absolute right-0 top-full text-center w-48 bg-white shadow-lg rounded-md">
                <ul className="py-2">
                  <li>
                    <Link
                      to="/productcategory/women"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Women
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/productcategory/men"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Men
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/productcategory/bags"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Bags
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/productcategory/decor"
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    >
                      Home Decor
                    </Link>
                  </li>
                </ul>
              </div>
            )} */}
          </div>
        </div>


        <div class="hidden w-full navbar-menu lg:order-3 lg:block lg:w-2/5 lg:text-right">
     
        {/* <a class="block mt-4 mr-10 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600" href="#">
            FAQ
        </a> */}
     
     <div className="w-56 bg-white  border-none rounded-b-lg h-8 -mb-8 mr-10  ">
<input type="text" name="search" id="search" placeholder={  'Search'} 
   className=" 
   rounded-lg border-lg flex-1  appearance-none border  border-gray-200 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-lime-600 focus:border-transparen
   w-full bg-white border-lg ring-lime-600 rounded-lg  bg-opacity-100 hover:bg-opacity-100 p-4  focus:outline-none focus:border-none active:outline-none
   
   "
   value={searchQuery}
   onChange={(e) => setSearchQuery(e.target.value)}
   // value= {search} onChange={(e)=>fetchPost(e.target.value)}
   />
{/* <button type="submit" className="ml-1 mb-6 outline-none border-none focus:border-none  active:outline-none  ">

<svg class="h-8 w-8 text-lime-600  transition-transform duration-300 hover:rotate-12"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="11" cy="11" r="8" />  <line x1="21" y1="21" x2="16.65" y2="16.65" /></svg>
</button> */}


</div>


          <a class="block mt-4 mr-10  text-black text-lg lg:inline-block lg:mt-0 hover:text-indigo-600" href="/profile">
            Profile
        </a>

            {/* Add currency selector in the header */}
            <div className="block mt-4 mr-20 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600">
            <Select
              options={currencyOptions}
              value={selectedCurrency}
              onChange={handleCurrencyChange}  // Call the new handler
              className="text-gray-900 "
              placeholder="Select Currency"
            />
          </div>
        {/* <a class="block mt-4 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600" href="/cart">
        <svg class="h-6 w-6 text-slate-500 top-0 right-0 absolute m-5"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="9" cy="21" r="1" />  <circle cx="20" cy="21" r="1" />  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>

        </a> */}

        <div class="block  text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600"
      
      >
          <div class="relative">
          <a class="block mt-4 text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600" href="/book">

          <svg class="h-10 w-8 text-slate-500 -top-2 right-9 absolute "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z" /></svg>
          <button type="button" class="absolute  right-10 w-5 h-5 -mt-5 font-bold  text-red-500  rounded-full fill-current">
  <span class="text-md">
      {book?book.length:"0"}
  </span>
</button>
</a>
          <a class="block  text-blue-900 lg:inline-block lg:mt-0 hover:text-indigo-600" href="/cart">

          <svg class="h-10 w-8 text-slate-500 -top-2 right-0 absolute  "  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <circle cx="9" cy="21" r="1" />  <circle cx="20" cy="21" r="1" />  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" /></svg>
          <button type="button" class="absolute  right-0 w-5 h-5 -mt-5 font-bold  text-red-500  rounded-full fill-current">
  <span class="text-md">
      {cart?cart.length:"0"}
  </span>
</button>
</a>
          </div>
         
      </div>
    </div>
        {/* <div className="hidden w-full lg:flex lg:order-3 lg:w-2/5 lg:text-right">
          <Link to="/shop" className="block mt-4 mr-10 text-blue-900 lg:mt-0 hover:text-indigo-600">
            Shop
          </Link>
          <Link to="/contact" className="block mt-4 mr-10 text-blue-900 lg:mt-0 hover:text-indigo-600">
            Contact
          </Link>
          <div className="relative inline-block mt-4">
            <Link to="/cart">
              <svg className="h-10 w-8 text-slate-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <circle cx="9" cy="21" r="1" />
                <circle cx="20" cy="21" r="1" />
                <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
              </svg>
              <span className="absolute top-0 right-0 inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white bg-red-600 rounded-full">
                {cart ? cart.length : "0"}
              </span>
            </Link>
          </div>
        </div> */}
      </div>

      {/* Mobile Menu */}
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="lg:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link to="/" className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium">
              Home
            </Link>
            <Link to="/about" className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium">
              About Us
            </Link>
            <Link to="/contact" className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium">
              Contact
            </Link>
                    
            {user ? (
              <Link to="/profile" className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium">
                Profile
              </Link>
            ) : (
              <Link to="/consumerlogin" className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium">
                Login
              </Link>
            )}
             <Select
              options={currencyOptions}
              value={selectedCurrency}
              onChange={handleCurrencyChange}  // Call the new handler
              className="block px-3 py-2 text-gray-900 hover:bg-gray-100 rounded-md text-base font-medium "
              placeholder="Select Currency"
            />   
          </div>
        </div>
      </Transition>
      <div class={`container fixed right-64 flex flex-col items-center justify-center w-96 bg-white rounded-lg shadow-lg z-50 ${results.length != 0 ? "h-80 pt-3" : ""} overflow-auto`}>
   <ul class="flex flex-col divide-y divide">
      {results.map((result) => {
         return (
            <li class="flex flex-row">
               <a href={`/singleproduct/${result._id}`} class="relative block">
                  <div class="flex items-center flex-1 p-4 cursor-pointer select-none">
                     <div class="flex flex-col items-center justify-center w-10 h-10 mr-4">
                        <img alt="profil" src={result.pic[0]} class="mx-auto object-cover rounded-sm h-10 w-10" />
                     </div>
                     <div class="flex-1 pl-1 mr-16">
                        <div class="font-medium">
                           {result.name}
                        </div>
                        <div class="text-sm text-gray-600">
                           {result.description}
                        </div>
                     </div>
                     <div class="text-xs text-gray-600">
                        {result.category}
                     </div>
                  </div>
               </a>
            </li>
         );
      })}
   </ul>
</div>


    </nav>
  );
}

export default Header;
