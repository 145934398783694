import React, {useContext, useRef, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { Carousel } from 'react-responsive-carousel';
import {UserContex} from '../App'
import NavBar from './nav'
require('react-datepicker/dist/react-datepicker.css')
const Inventory = ()=>{


  const history = useNavigate()
  const {state, dispatch}= useContext(UserContex)

  const  [data, setData]= useState([])

  const [search,setSearch] = useState("")

  const [addStock,setAddStock]= useState(false)
  const [editStock,setEditStock]= useState(false)

  const[name,setName]= useState("")
  const[nameEdit,setNameEdit]= useState("")
  const [totalSum,setStockSum] = useState()
  const[idEdit,setidEdit]= useState("")
  const[category,setCategory]= useState("")
  const[Currency,setCurrency]= useState("")
  const[Price,setPrice]= useState("")
  const[quantity,setQuantity]= useState()
  const[quantityEdit,setQuantityEdit]= useState()
  const [sizes, setSizes] = useState([]);
  const[rate,setRate]= useState()
  const[bill,setBill]= useState()
  const [updateDate, setupdateDate]= useState(new Date())
  const[buyrate,setBuyRate]= useState()
  const[rateEdit,setRateEdit]= useState()
  const[weight,setWeight]= useState()
  const[parentcategory,setParentCategory]= useState("")
  const[description,setDescription]= useState()
  const [date,setDate] = useState(new Date())
  const [checkdate,setCheckDate] = useState(new Date())


  const [url,setUrl] = useState([])  
  const [pricing,setPricing] = useState([])  

  const[progress,setProgress]= useState(false)






  useEffect(  ()=>{

    fetch('/getStock',{
      headers:{
          Authorization: "Bearer " +localStorage.getItem("jwt")
          
      }
  }).then(res=>res.json())
  .then(result=>{
      setData(result.stocks)

      let sum =0

      result.stocks.map(sales=>{
        sum+=(sales.rate*sales.quantity)

      })

      setStockSum(sum)
      
  })

  },[])


  const postStock = ()=>{

    fetch('/addStock',{

      method:"post",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        name:name,
        quantity:quantity,
        rate:rate,
        buyrate:buyrate,
        bill:bill,
        expire:date,
        pic:url,
        size:sizes,
        pricing:pricing,
        description:description,
        category:category,
        gender:parentcategory,
        weight

      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload(false);
      }
    })
    .catch(err=>{
      console.log(err)
    })
    history('/inventory')

  }


  const postStockUpdate = ()=>{

    fetch('/updateStock',{

      method:"put",
      headers:{
        "Content-Type":"application/json",
        Authorization: "Bearer " +localStorage.getItem("jwt")

      },
      body: JSON.stringify({
        id:idEdit,
        name:name,
        quantity:quantity,
        rate:rate,
        buyrate:buyrate,
        bill:bill,
        pic:url,
        size:sizes,
        pricing:pricing,
        expire:updateDate,
        description:description,
        category:category,
        gender:parentcategory,
        weight

      })
      
    }).then(res=>res.json())
    .then(data=>{
      if(data.error){
        console.log("Error")
      }

      else{
        window.location.reload(false);

        history('/inventory')
      }
    })
    .catch(err=>{
      console.log(err)
    })
   // history.push('/inventory')

  }


  const fetchPost=(query)=>{


    setSearch(query)

    fetch('/search-stock',{

      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),

        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        query
      })

    }).then(res=> res.json())
      .then(results=>{
        
        setData(results.stock)


      let sum =0

      results.stock.map(sales=>{
        sum+=sales.rate*sales.quantity

      })

      setStockSum(sum)
      })


  }

  const singleStock = (Itemid)=>{
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    fetch('/getSingleStock',{
      method:"post",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        id:Itemid
      })
  }).then(res=>res.json())
  .then(result=>{


    setidEdit(result.stock._id)
    setName(result.stock.name)
    setQuantity(result.stock.quantity)
    setRate(result.stock.rate)
    setBuyRate(result.stock.buyrate)
    setBill(result.stock.bill)
    setSizes(result.stock.size)
    setDescription(result.stock.description)
    setUrl(result.stock.pic)
    setPricing(result.stock.pricing)
    setCategory(result.stock.category)
    setParentCategory(result.stock.gender)
    setupdateDate( new Date(result.stock.expire))

  })

  }
  const deleteStock = (Itemid)=>{
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    fetch('/deletestock',{
      method:"delete",
      headers:{
        Authorization: "Bearer " +localStorage.getItem("jwt"),
        "Content-Type":"application/json"

      },
      body:JSON.stringify({
        id:Itemid
      })
  }).then(res=>res.json())
  .then(result=>{


    window.location.reload(false)

  })

  }


  
  const uploadppt=({target:{files}})=>{

     setProgress(true)

    let data = new FormData()

    for(const file of files){

   data.append('uploadimage', file)
   data.append('name',file.name)
}
       fetch("/uploadimage", {

         method:"post",
         body: data
     })
     .then(res=>res.json())
     .then(data=>{

           setUrl(old=>[...old, data.url])
           setProgress(false)

      

       
     })
     .catch(err=>{
         console.log(err)
     })

}
  
const ref = useRef(null);
const handleClick = () => {
ref.current?.scrollIntoView({behavior: 'smooth'});
};


const scrollToTop = () => {
  console.log('scrollToTop called');
  window.scrollTo({ top: 0, behavior: 'smooth' });
};


const removePicture = (index,amt) => {
    
    
  setUrl([
    ...url.slice(0, index),
    ...url.slice(index + 1, url.length)
  ]);


  // setCreditItem([
  //   ...creditItem.slice(0, index),
  //   ...creditItem.slice(index + 1, creditItem.length)
  // ]);

  // setAmount(prevCount => prevCount - amt)

}


const removePricing = (index,amt) => {
    
    
  setPricing([
    ...pricing.slice(0, index),
    ...pricing.slice(index + 1, pricing.length)
  ]);

}


const addPricing = (curry,price) => {
    
    
  setPricing(old=>[...old, {currency:curry,price:price}])

setPrice(0)
}



const handleSizeChange = (size) => {
  setSizes((prevSizes) =>
    prevSizes.includes(size)
      ? prevSizes.filter((s) => s !== size) // Remove size if already selected
      : [...prevSizes, size] // Add size if not selected
  );
};



return(

    <div>


 <script src="https://cdn.jsdelivr.net/gh/alpinejs/alpine@v2.x.x/dist/alpine.min.js" defer></script>

 <div  className="flex h-screen bg-white">

 <div className=" "></div>

        <link rel="stylesheet" href="https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css" />
  






        <div className="flex-1 flex flex-col overflow-hidden ">

            
          <NavBar/>


            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
  <div className="container mx-auto px-6 py-8">
    <h3 className="text-gray-400 text-3xl font-medium">Inventory</h3>


<div className="flex md:flex-row flex-col ...">


<div class="shadow-lg rounded-xl bg-gray-200 w-full md:w-64 p-6 bg-white dark:bg-gray-500 relative overflow-hidden">
    <p class="text-black text-xl">
        Items
    </p>
    <div class="flex items-center my-4 text-blue-500 rounded justify-between">
        <span class="rounded-lg p-2 bg-white">
        <svg class="h-8 w-8 text-gray-400"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M14.5 10c-.83 0-1.5-.67-1.5-1.5v-5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5z" />  <path d="M20.5 10H19V8.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />  <path d="M9.5 14c.83 0 1.5.67 1.5 1.5v5c0 .83-.67 1.5-1.5 1.5S8 21.33 8 20.5v-5c0-.83.67-1.5 1.5-1.5z" />  <path d="M3.5 14H5v1.5c0 .83-.67 1.5-1.5 1.5S2 16.33 2 15.5 2.67 14 3.5 14z" />  <path d="M14 14.5c0-.83.67-1.5 1.5-1.5h5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-5c-.83 0-1.5-.67-1.5-1.5z" />  <path d="M15.5 19H14v1.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />  <path d="M10 9.5C10 8.67 9.33 8 8.5 8h-5C2.67 8 2 8.67 2 9.5S2.67 11 3.5 11h5c.83 0 1.5-.67 1.5-1.5z" />  <path d="M8.5 5H10V3.5C10 2.67 9.33 2 8.5 2S7 2.67 7 3.5 7.67 5 8.5 5z" /></svg>        </span>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
                {data.length ? data.length:0}
            </p>
            <p class="text-gray-700 text-sm">
                Total Item Types
            </p>
        </div>
    </div>
   
    <div class="mt-4">
        <button type="button" class="py-2 px-4  bg-black hover:bg-gray-400 focus:ring-gray-400 focus:ring-offset-gray-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md "
        
        onClick={()=>setAddStock(true)}
        >
            Add Stocks +
        </button>
    </div>
</div>


<div class="shadow-lg rounded-xl bg-gray-200 w-full h-32 mt-3 md:mt-0 md:ml-4 md:w-64 p-6 bg-white dark:bg-gray-500  overflow-hidden">
    <p class="text-black text-xl">
        Total Amount: 
    </p>
    <div class="flex items-center my-1 text-blue-500 rounded justify-between">
        <span class="rounded-lg p-1 bg-white">
        <svg class="h-8 w-8 text-gray-400"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"/>
</svg>
        
            </span>
        <div class="flex flex-col w-full ml-2 items-start justify-evenly">
            <p class="text-black text-lg">
            NU {totalSum}            </p>
            <p class="text-gray-700 text-sm">
                Total Amount 
            </p>
        </div>
    </div>
   
   
</div>


<div class="relative p-4 md:ml-4 overflow-hidden text-gray-700 bg-white shadow-lg rounded-xl w-42 md:w-42 mt-2 md:mt-0 h-32 dark:bg-gray-800 dark:text-gray-100">
    <a href="#" class="block w-full h-full">
        <div class="w-full">
            <p class="mb-1 text-2xl font-light text-gray-700 dark:text-white">
                Symbols
            </p>
            <div class="flex items-center justify-between text-sm text-gray-400">
                <p>
                    Out of Stock
                </p>
               
            </div>
            <div class="w-1/3 h-2 mb-3 bg-red-500 rounded-full">
                
            </div>
            <div class="flex items-center justify-between text-sm text-gray-400">
                <p>
                    Less Quantity
                </p>
                
            </div>
            <div class="w-1/3 h-2 mb-4 bg-yellow-500 rounded-full">
                
            </div>
           
           
        </div>
    </a>
</div>

                       

  
</div>

   
    
    <br></br>

    {
addStock? 
(

  <>
  
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full mb-5" >
      <div className="md:flex w-full">
        
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Add Product </h1>
          </div>
          <div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Quantity</span>
                <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={quantity}
                onChange={(e)=>setQuantity(e.target.value)} 
                ></input>
</div>
</div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Cost of Production</span>
<input  type="number" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Cost of Production"
value={buyrate}
onChange={(e)=>setBuyRate(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Selling Rate</span>
                <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={rate}
                onChange={(e)=>setRate(e.target.value)} 
                ></input>
</div>
</div>

<div class="grid max-w-xl grid-cols-3 gap-4 ">

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Currency</span>

<select id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none"           
          value={Currency}
          onChange={(e)=>setCurrency(e.target.value)}
          >
     <option >
            Select Currency
        </option>
        <option value="USD">
            USD
        </option>
        <option value="CAD">
            CAD
        </option>
        <option value="EUR">
            EUR
        </option>
        <option value="AUD" >
        AUD        
        </option>     
        <option value="INR" >
        INR
        </option>
       
        
  
{/* 
        {allAddress.map(item=>{

            return(

                <option 
                >
                {item}
            </option>
            )
        })} */}
      
       
    </select>
</div>
<div className="py-1">
<span className="px-1 text-sm text-gray-600">Price</span>

<input id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none"           
          value={Price}
          onChange={(e)=>setPrice(e.target.value)}
          >
  
       
    </input>
</div>
<div className="py-1">

<button id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2 mt-6
          bg-green-300 "           
          onClick={()=>{addPricing(Currency,Price)}}
          >
    
        + Add
      
       
    </button>
</div>
</div>


<div class="grid max-w-xl grid-cols-4 gap-4 ">

  {pricing.length>0?pricing.map((items,index)=>{

    return(
      <>

<div class="p-4 bg-white shadow-lg rounded-2xl  dark:bg-gray-800 relative">
    <div class="flex items-center">
        
        <p class="ml-2 text-gray-700 text-md dark:text-gray-50">
            {items.currency}
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-xl font-bold text-left text-gray-800 dark:text-white">
          {items.price}
        </p>
       
    </div>
    <svg class="h-8 w-8 text-red-500 absolute bottom-0 right-0"  fill="none" viewBox="0 0 24 24" stroke="currentColor"
    onClick={()=>{removePricing(index)}}
    >
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

</div>

      </>
    )
  }):<></>}

</div>


<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Product Code</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Product Code"
value={bill}
onChange={(e)=>setBill(e.target.value)}
>

</input>
</div>

                      
 <div className="py-1">
                 <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Mfd</label>
                 <DatePicker className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={date} onChange={date => setDate(date)} />

              </div>
</div>
<div className="py-1">
<span className="px-1 text-sm text-gray-600">Weight</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Weight"
value={weight}
onChange={(e)=>setWeight(e.target.value)}
>

</input>
</div>

<div className="py-1">
  <span className="px-1 text-sm text-gray-600">Sizes</span>
  <div className="space-y-2 grid grid-cols-5">
    {["Small", "Medium", "Large", "XL", "XXL"].map((size) => (
      <label key={size} className="flex items-center space-x-2">
        <input
          type="checkbox"
          value={size}
          checked={sizes.includes(size)}
          onChange={() => handleSizeChange(size)}
          className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
        />
        <span className="text-gray-600">{size}</span>
      </label>
    ))}
  </div>
</div>


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Description</span>
<textarea  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Product Description"
value={description}
onChange={(e)=>setDescription(e.target.value)}
>

</textarea>
</div>


<div class="grid max-w-xl grid-cols-2 gap-4 ">

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Gender</span>

<select id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none"           
          value={parentcategory}
          onChange={(e)=>setParentCategory(e.target.value)}
          >
     <option >
            Select Gender
        </option>
        <option value="Men">
            Men
        </option>
        <option value="Women" >
        Women        
        </option>     
        <option value="Both" >
        Both
        </option>
       
        
  
{/* 
        {allAddress.map(item=>{

            return(

                <option 
                >
                {item}
            </option>
            )
        })} */}
      
       
    </select>
</div>
<div className="py-1">
<span className="px-1 text-sm text-gray-600">Category</span>

<select id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none"           
          value={category}
          onChange={(e)=>setCategory(e.target.value)}
          >
     <option >
            Select Category
        </option>
        <option value="Bags">
            Bags
        </option>
        <option value="Jackets" >
        Jackets        
        </option>
        <option value="Decore" >
        Home Decore
        </option>
        <option value="Shawl/Stole/Muffler" >
        Shawl/Stole/Muffler
        </option>
        <option value="Tote bags" >
        Tote bags
        </option>
        <option value="Cross body bags" >
        Cross body bags
        </option>
       
        <option value="Backpacks" >
        Backpacks
        </option>
        <option value="Clutches & evening bags" >
        Clutches & evening bags
        </option>
       
        
  
{/* 
        {allAddress.map(item=>{

            return(

                <option 
                >
                {item}
            </option>
            )
        })} */}
      
       
    </select>
</div>
</div>
 
         <div className="col-span-4 w-full mb-5 ">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>



 

    
                               </div>



  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
                  onClick={() => {setAddStock(false);postStock()}}

      > Add Stock</button>
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold mt-4"
                  onClick={() => {setAddStock(false)}}

      > Cancel</button>
                  


    </div>



    
</div>
       
</div>

<div className=" md:block md:w-1/2 w-full  bg-white py-10 px-10">



<div class="container flex flex-col items-center justify-center w-full mx-auto ">
    <ul class="grid md:grid-cols-2 grid-cols-1">

      {url?url.map((item,index)=>{

        return( <li class="flex flex-row mb-2 ">
          <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                  <a href="#" class="relative block">
                      <img alt="profil" src={item} class="mx-auto object-cover rounded-sm h-48 w-48 "/>
                  </a>
             
             
            
              <button class="flex justify-end w-24 text-right"
              onClick={()=>{removePicture(index)}}
              >
              <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
              </button>
          </div>
      </li>)

      }):<></>}
       
    
      
    </ul>
</div>



{/* <Carousel showArrows={true} >
         
         
               {url ? url.map(pic=>{
                return(
                  <div>
                  <img src={pic} alt="medicine"  />
          
                   </div>
                )
               }):<></> }         
        
       
         
        
       

   

     </Carousel> */}


     {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}

</div>
          </div>
        </div>
  </>






)


:null

}
    {
editStock? 
(

  <>
  
    <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-xl w-full mb-5"  id='top'>
      <div className="md:flex w-full">
        
        <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
          <div className="text-center mb-10">
            <h1 className="font-bold text-3xl text-gray-900">Update Product </h1>
          </div>
          <div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Name</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Name"
value={name}
onChange={(e)=>setName(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Quantity </span>
                <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={quantity}
                onChange={(e)=>setQuantity(e.target.value)} 
                ></input>
</div>
</div>
<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Cost of Production</span>
<input  type="number" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Cost of Production"
value={buyrate}
onChange={(e)=>setBuyRate(e.target.value)}
>

</input>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Selling Rate</span>
                <input type="number"  className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
                bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 
                
                value={rate}
                onChange={(e)=>setRate(e.target.value)} 
                ></input>
</div>
</div>


<div class="grid max-w-xl grid-cols-3 gap-4 ">

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Currency</span>

<select id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none"           
          value={Currency}
          onChange={(e)=>setCurrency(e.target.value)}
          >
     <option >
            Select Currency
        </option>
        <option value="USD">
            USD
        </option>
        <option value="CAD">
            CAD
        </option>
        <option value="EUR">
            EUR
        </option>
        <option value="AUD" >
        AUD        
        </option>     
        <option value="INR" >
        INR
        </option>
       
        
  
{/* 
        {allAddress.map(item=>{

            return(

                <option 
                >
                {item}
            </option>
            )
        })} */}
      
       
    </select>
</div>
<div className="py-1">
<span className="px-1 text-sm text-gray-600">Price</span>

<input id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none"           
          value={Price}
          onChange={(e)=>setPrice(e.target.value)}
          >
  
       
    </input>
</div>
<div className="py-1">

<button id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2 mt-6
          bg-green-300 "           
          onClick={()=>{addPricing(Currency,Price)}}
          >
    
        + Add
      
       
    </button>
</div>
</div>


<div class="grid max-w-xl grid-cols-4 gap-4 ">

  {pricing.length>0?pricing.map((items,index)=>{

    return(
      <>

<div class="p-4 bg-white shadow-lg rounded-2xl  dark:bg-gray-800 relative">
    <div class="flex items-center">
        
        <p class="ml-2 text-gray-700 text-md dark:text-gray-50">
            {items.currency}
        </p>
    </div>
    <div class="flex flex-col justify-start">
        <p class="text-xl font-bold text-left text-gray-800 dark:text-white">
          {items.price}
        </p>
       
    </div>
    <svg class="h-8 w-8 text-red-500 absolute bottom-0 right-0"  fill="none" viewBox="0 0 24 24" stroke="currentColor"
    onClick={()=>{removePricing(index)}}
    >
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>

</div>

      </>
    )
  }):<></>}

</div>


<div class="grid max-w-xl grid-cols-2 gap-4 ">


<div className="py-1">
<span className="px-1 text-sm text-gray-600">Product Code</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Product Code"
value={bill}
onChange={(e)=>setBill(e.target.value)}
>

</input>
</div>

                      
 <div className="py-1">
                 <label htmlFor="email_address" className="block text-sm font-medium text-gray-700">Mfd</label>
                 <DatePicker className="rounded-lg w-full
          bg-white border-2 border-gray-300 placeholder-gray-400 focus:placeholder-gray-200 focus:bg-white focus:border-indigo-300  focus:outline-none" selected={updateDate} onChange={date => setupdateDate(date)} />

              </div>
</div>

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Weight</span>
<input  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Weight"
value={weight}
onChange={(e)=>setWeight(e.target.value)}
>

</input>
</div>

<div className="py-1">
  <span className="px-1 text-sm text-gray-600">Sizes</span>
  <div className="space-y-2 grid grid-cols-5">
    {["Small", "Medium", "Large", "XL", "XXL"].map((size) => (
      <label key={size} className="flex items-center space-x-2">
        <input
          type="checkbox"
          value={size}
          checked={sizes.includes(size)}
          onChange={() => handleSizeChange(size)}
          className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
        />
        <span className="text-gray-600">{size}</span>
      </label>
    ))}
  </div>
</div>
<div className="py-1">
<span className="px-1 text-sm text-gray-600">Description</span>
<textarea  type="text" className="space-y-6 text-md block px-3 py-2 rounded-lg w-full
bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none" 

placeholder="Product Description"
value={description}
onChange={(e)=>setDescription(e.target.value)}
>

</textarea>
</div>

<div class="grid max-w-xl grid-cols-2 gap-4 ">

<div className="py-1">
<span className="px-1 text-sm text-gray-600">Gender</span>

<select id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none"           
          value={parentcategory}
          onChange={(e)=>setParentCategory(e.target.value)}
          >
     <option >
            Select Gender
        </option>
        <option value="Men">
            Men
        </option>
        <option value="Women" >
        Women        
        </option>     
        <option value="Both" >
        Both
        </option>
       
        
  
{/* 
        {allAddress.map(item=>{

            return(

                <option 
                >
                {item}
            </option>
            )
        })} */}
      
       
    </select>
</div>
<div className="py-1">
<span className="px-1 text-sm text-gray-600">Category</span>

<select id="animals" class="space-y-6  text-md block px-3 py-2 rounded-lg w-full mb-2
          bg-white border-2 border-gray-300 placeholder-gray-400  focus:placeholder-gray-500 focus:bg-white focus:border-indigo-300  focus:outline-none"           
          value={category}
          onChange={(e)=>setCategory(e.target.value)}
          >
     <option >
            Select Category
        </option>
        <option value="Bags">
            Bags
        </option>
        <option value="Jackets" >
        Jackets        
        </option>
        <option value="Decore" >
        Home Decore
        </option>
        <option value="Shawl/Stole/Muffler" >
        Shawl/Stole/Muffler
        </option>
        <option value="Tote bags" >
        Tote bags
        </option>
        <option value="Cross body bags" >
        Cross body bags
        </option>
       
        <option value="Backpacks" >
        Backpacks
        </option>
        <option value="Clutches & evening bags" >
        Clutches & evening bags
        </option>
       
        
  
{/* 
        {allAddress.map(item=>{

            return(

                <option 
                >
                {item}
            </option>
            )
        })} */}
      
       
    </select>
</div>
</div>
 
         <div className="col-span-4 w-full mb-5 ">
                        <label className="flex flex-col items-center  bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-300 hover:text-white">
         <svg className="w-6 h-6" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
             <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
         </svg>
        <span className="mt-2 text-base leading-normal">Upload</span>
        <input type='file' className="hidden" 
        onChange={uploadppt}
        />
        
    </label>



 

                               </div>



  <div className="w-full px-3 mb-5">

    
  
      <button className="block w-full max-w-xs mx-auto bg-blue-300 hover:bg-blue-400 focus:bg-blue-400 text-white rounded-lg px-3 py-3 font-semibold"
             onClick={() => {setEditStock(false); postStockUpdate();}}

      > Update Stock</button>
                  
      <button className="block w-full max-w-xs mx-auto bg-red-300 hover:bg-red-400 focus:bg-red-400 text-white rounded-lg px-3 py-3 font-semibold mt-1"
             onClick={() => {setEditStock(false);}}

      > Cancle</button>
                  


    </div>



    
</div>
       
</div>

<div className=" md:block md:w-1/2 w-full  bg-white py-10 px-10 h-5/6 overflow-auto">

{/* 
<Carousel showArrows={true} >
         
         
               {url ? url.map(pic=>{
                return(
                  <div>
                  <img src={pic} alt="medicine"  />
          
                   </div>
                )
               }):<></> }         
        
       
         
        
       

   

     </Carousel> */}


<div class="container flex flex-col items-center justify-center w-full mx-auto ">
    <ul class="grid md:grid-cols-2 grid-cols-1">

      {url?url.map((item,index)=>{

        return( <li class="flex flex-row mb-2 ">
          <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                  <a href="#" class="relative block">
                      <img alt="profil" src={item} class="mx-auto object-cover rounded-sm h-full w-full "/>
                  </a>
             
             
            
              <button class="flex justify-end w-24 text-right"
              onClick={()=>{removePicture(index)}}
              >
              <svg class="h-8 w-8 text-red-500"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="4" y1="7" x2="20" y2="7" />  <line x1="10" y1="11" x2="10" y2="17" />  <line x1="14" y1="11" x2="14" y2="17" />  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
              </button>
          </div>
      </li>)

      }):<></>}
       
    
      
    </ul>
</div>

     {progress?         <span className=" text-base leading-normal"> Uploading...</span>:   <span className=" text-base leading-normal"> Upload Photo </span>
         }
                <input type='file' className="hidden"  required
                onChange={uploadppt}
                />
                
        
            {progress?
                <svg class="h-12 w-12 text-blue-500 animate-spin"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 4.55a8 8 0 0 1 6 14.9m0 -4.45v5h5" />  <path d="M11 19.95a8 8 0 0 1 -5.3 -12.8" stroke-dasharray=".001 4.13" /></svg>
            :<></>}

</div>
          </div>
        </div>
  </>






)




:null

}








    <div className="w-full h-10 pl-3 pr-2 bg-gray-100 border-gray-50 rounded-full  outline-none  border-none flex focus:outline-none justify-between items-center relative">
  <input type="text" name="search" id="search" placeholder="Search" 
         className=" w-full bg-gray-100 border-none rounded-full    focus:outline-none focus:border-white active:outline-none"
         value= {search} onChange={(e)=>fetchPost(e.target.value)}
         />
  <button type="submit" className="ml-1 outline-none border-none focus:border-none  active:outline-none">
    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
         viewBox="0 0 24 24" className="w-6 h-6">
      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
    </svg>
  </button>
</div>

<div class="relative w-full overflow-hidden bg-white  shadow-xl dark:bg-gray-700 rounded-t-lg">
      <div class="z-100 grid md:grid-cols-4 grid-cols-3 gap-2 justify-items-center place-items-center md:px-36 px-4  py-6 ">
         
<span class="px-4 py-2 flex items-center text-base rounded-full text-white  bg-lime-600 cursor-pointer"
onClick={()=>{fetchPost("Jackets")}}
>
<svg class="h-6 w-6 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M15 4l6 2v5h-3v8a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1v-8h-3v-5l6 -2a3 3 0 0 0 6 0" /></svg>
Jackets
</span>

    

     
<span class="px-4 py-2 flex items-center text-base rounded-full text-white  bg-lime-600 cursor-pointer "
onClick={()=>{fetchPost("Bags")}}

>
<svg class="h-6 w-6 text-white"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"/>
</svg>

  Bags
</span>
<span class="px-4 py-2 flex items-center text-base rounded-full text-white  bg-lime-600 cursor-pointer  "
onClick={()=>{fetchPost("Decore")}}

>
<svg class="h-6 w-6 text-white mr-1"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <rect x="2" y="7" width="20" height="15" rx="2" ry="2" />  <polyline points="17 2 12 7 7 2" /></svg>

  Decore
</span>
<span class="px-4 py-2 flex items-center text-base rounded-full text-white  bg-lime-600 cursor-pointer  "
onClick={()=>{fetchPost("Shawl")}}

>
<svg class="h-6 w-6 text-white mr-1"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round">  <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" /></svg>
Shawl/Stole

</span>

          
      </div>
     
</div>



    <div className="mt-8">
    </div>
    <div className="flex flex-col mt-8">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                Product
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
               COP                   
                   </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                Selling Price                   
                   </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Qnty
                  
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Weight
                  
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Gender
                  
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Mfd
                  
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Code
                  
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Edit
                  
                  </th>
                <th className="px-6 py-3 border-b border-gray-200 bg-black text-left text-xs leading-4 font-medium text-gray-100 uppercase tracking-wider">
                  
                Action
                  
                  </th>
              
                
              </tr>
            </thead>




 <tbody className="bg-white">
            {data?data.map(item=>{
              var diffDays 

              if(item.expire){
                   var a =item.expire.substring(0,10)

                var b = checkdate.toISOString().substring(0,10)


                var date1 = new Date(b);
                var date2 = new Date(a);
                
                var timeDiff = Math.abs(date2.getTime() - date1.getTime());
                diffDays  = Math.ceil(parseInt((date2 - date1) / (24 * 3600 * 1000)));

                
              }


       

              return(

                
         
              <tr data-href="#edit" key={item._id} style={{cursor:"pointer"}} class={item.quantity<=0?"bg-red-200":item.quantity>0&& item.quantity<=5?"bg-yellow-200":"bg-white" }>

                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  
                  onClick={()=>{setEditStock(true);singleStock(item._id)}}
                  >
                             
                  <div className="flex items-center">
                                    
                    <div className="ml-4">
                      
                      <img class="w-24 h-24" src={item.pic?item
                      .pic[0]:"./pcm.png"}></img>
                                            <div className="text-sm leading-5 font-medium text-gray-900">{item.name}
                      </div>
                    </div>
                  </div>
                </td>


                
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.buyrate}</div>
                </td>
               
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                  onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.rate}</div>
                </td>
               
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
               onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.quantity}</div>
                </td>             
               
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
               onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.weight}</div>
                </td>             
               
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
               onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.gender}</div>
                </td>             
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
               onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.expire?item.expire.substring(0, 10):""}</div>


              </td>             
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
               onClick={()=>{setEditStock(true);singleStock(item._id);handleClick()}}

                >
                  <div className="text-sm leading-5 text-gray-900">{item.bill}</div>
                </td>  

                  <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                                        // onClick={()=>{if(window.confirm('Are you sure you want to DELETE?'))   deleteStock(item._id)}}                           

                                        onClick={()=>{setEditStock(true);singleStock(item._id)}}

                >
<a href="#top">
<svg class="h-8 w-8 text-lime-500"  viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M9 7 h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />  <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />  <line x1="16" y1="5" x2="19" y2="8" /></svg>
                  </a>


                </td>          
                <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200"
                                        onClick={()=>{if(window.confirm('Are you sure you want to DELETE?'))   deleteStock(item._id)}}                           

                >
<svg class="h-8 w-8 text-red-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
</svg>

                </td>         
                    

                         
              </tr> 


              )
            }) :<></>}
           
  
             
            </tbody>

          
          </table>
        </div>
      </div>
    </div>
  </div>
</main>

</div>

    </div>
  </div>  
);


  
}


export default Inventory






//  <>

// <div class="flex flex-row">


// </div>



// </> 